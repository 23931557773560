import React from 'react';

type VerticalContentProps = {
  children: React.ReactNode;
  className?: string;
  position?: 'left' | 'center';
  gap?:
    | 'none'
    | 'tiny'
    | 'smaller'
    | 'small'
    | 'content-padding'
    | 'large'
    | 'larger'
    | 'xlarge';
  style?: React.CSSProperties;
} & React.ComponentProps<'div'>;

const VerticalContent = React.forwardRef(function VerticalContent(
  {
    children,
    className = '',
    position = 'center',
    gap = 'content-padding',
    style = {},
    ...rest
  }: VerticalContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  return (
    <div
      className={`vertical-content ${className} ${
        position ? `vertical-content--position-${position}` : ''
      } ${gap ? `vertical-content--gap-${gap}` : ''}`}
      style={style}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

export default VerticalContent;
