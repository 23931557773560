import { useEffect, useState } from 'react';

type ScriptLoadStatus = 'loading' | 'idle' | 'ready' | 'error';

/**
 * This hook lets you asynchronously load a Javascript file.
 * from: https://usehooks.com/useScript/
 *
 * @example
 * const status = useScript(
 *   "https://example.tld/script.js"
 * );
 * { status === ready && <ComponentToRenderOnceScriptIsLoaded /> }
 *
 * @param src
 * @returns {string}
 */
function useScript(src?: string | null): ScriptLoadStatus {
  const [status, setStatus] = useState<ScriptLoadStatus>(
    src ? 'loading' : 'idle'
  );

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another instance of this hook or be
      // generally present from e.g. a blade or CMS head
      const existingScript = document.querySelector(`script[src="${src}"]`);

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      function setAttributeFromEvent(this: Element, event: Event | ErrorEvent) {
        const status = event.type === 'load' ? 'ready' : 'error';
        this.setAttribute('data-status', status);
        setStatus(status);
      }

      if (existingScript) {
        const status = existingScript.getAttribute('data-status');

        // ugly, but since the source of the script isn't this hook, we need
        // to assume the script is ready.
        if (!status || status === 'ready') {
          setStatus('ready');
          return;
        }

        // required type check
        if (status === 'loading' || status === 'error') {
          setStatus(status);
        }

        // Add event listeners
        existingScript.addEventListener('load', setAttributeFromEvent);
        existingScript.addEventListener('error', setAttributeFromEvent);

        // Remove event listeners on cleanup
        return () => {
          if (existingScript) {
            existingScript.removeEventListener('load', setAttributeFromEvent);
            existingScript.removeEventListener('error', setAttributeFromEvent);
          }
        };
      }

      const script = Object.assign(document.createElement('script'), {
        src,
        async: true,
      });
      script.dataset.status = 'loading';

      // Add script to document body
      document.head.appendChild(script);

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);

      return () => {
        if (script) {
          script.removeEventListener('load', setAttributeFromEvent);
          script.removeEventListener('error', setAttributeFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
}

export default useScript;
