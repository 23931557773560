import React from 'react';
import { normalizeType } from '../EnhancedMarkdown/components/EnhancedMarkdownStyled';
import { normalizeAbilityType } from '../EnhancedMarkdown/helpers/abilitySchools';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip';

export type BarProps = {
  type?: string;
  percent: number | string;
  height?: number;
  tooltipProps?: Omit<TooltipProps, 'kind' | 'children'>;
};

export default function Bar({
  type,
  percent,
  height = 16,
  tooltipProps,
}: BarProps): JSX.Element {
  const child = (
    <div
      className={`bar ${normalizeType(normalizeAbilityType(type))}-bg`}
      style={{ width: `${percent}%`, height: `${height}px` }}
    />
  );

  if (tooltipProps) {
    return (
      <Tooltip
        {...tooltipProps}
        placementOptions={{
          alignment: 'start',
        }}
      >
        {child}
      </Tooltip>
    );
  }

  return child;
}
